import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { App } from 'src/App/App';

import { getPath } from '@speeki/client-admin';
import { resolveEnv } from '@speeki/frontend-utils';

import { AcademyPlaceholder } from '@pages/AcademyPlaceholder/AcademyPlaceholder';
import { AssessmentPlaceholder } from '@pages/AssessmentPlaceholder/AssessmentPlaceholder';
import { CarbonLensPlaceholder } from '@pages/CarbonLensPlaceholder/CarbonLensPlaceholder';
import { CertificatesPlaceholder } from '@pages/CertificatesPlaceholder/CertificatesPlaceholder';
import { CommsPlaceholder } from '@pages/CommsPlaceholder/CommsPlaceholder';
import { DashboardPlaceholder } from '@pages/DashboardPlaceholder/DashboardPlaceholder';
import { DocumentsPlaceholder } from '@pages/DocumentsPlaceholder/DocumentsPlaceholder';
import { LibraryPlaceholder } from '@pages/LibraryPlaceholder/LibraryPlaceholder';
import { TrainingPlaceholder } from '@pages/TrainingPlaceholder/TrainingPlaceholder';
import * as Sentry from '@sentry/react';

export const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes: RouteObject[] = [
    {
        children: [
            {
                element: <DashboardPlaceholder />,
                path: '/dashboard',
            },
            {
                element: <AssessmentPlaceholder />,
                path: '/tools/assessment',
            },
            {
                element: <CertificatesPlaceholder />,
                path: '/tools/assurance',
            },
            {
                element: <DocumentsPlaceholder />,
                path: '/tools/documents',
            },
            {
                element: <AcademyPlaceholder />,
                path: '/tools/academy',
            },
            {
                element: <CommsPlaceholder />,
                path: '/tools/comms',
            },
            {
                element: <TrainingPlaceholder />,
                path: '/tools/training',
            },

            {
                element: <LibraryPlaceholder />,
                path: '/tools/library',
            },
            {
                element: <CarbonLensPlaceholder />,
                path: '/tools/carbon-lens',
            },
            {
                element: (
                    <Navigate replace to={getPath({ path: '/dashboard' })} />
                ),
                path: '/',
            },
            {
                element: (
                    <Navigate replace to={getPath({ path: '/dashboard' })} />
                ),
                path: '*',
            },
        ],
        element: <App />,
        path: '/',
    },
];

const publicUrl = resolveEnv('PUBLIC_URL');

export const router = sentryCreateBrowserRouter(routes, {
    basename: publicUrl.endsWith('/') ? publicUrl : `${publicUrl}/`,
});
