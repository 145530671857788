import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from '@speeki/global-ui-components';

export const PlaceholderSection = styled.section<{
    gap?: CSSProperties['gap'];
}>(
    ({ gap }) => css`
        display: flex;
        flex-direction: column;
        gap: ${gap ? gap : '24px'};
    `,
);

export const ContentWrapper = styled.div<{
    gap?: CSSProperties['gap'];
}>(
    ({ gap }) => css`
        display: flex;
        flex-direction: column;
        gap: ${gap ? gap : '40px'};
    `,
);

export const ContentHeader = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
`;

export const ContentHeaderText = styled(Typography.TextBody_18_30)`
    max-width: 750px;
`;

export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const TableBlueText = styled.span(
    ({ theme: { palette } }) => css`
        color: ${palette.tertiary.default};
        cursor: pointer;

        &:hover {
            color: ${palette.tertiary.hover};
        }
    `,
);

export const HeaderActions = styled.div`
    display: flex;
    gap: 10px;
`;

export const EmptySpaceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const TextContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const EnableFeatureText = styled(Typography.TextBody_16_24)`
    margin-top: 12px;
`;

export const LinkText = styled.a(
    ({ theme: { palette } }) => css`
        color: ${palette.tertiary.default};
        text-decoration: none;

        &:hover {
            color: ${palette.tertiary.hover};
        }
    `,
);
