import { useNavigation } from '@speeki/client-admin';
import {
    Button,
    TableEmptyState,
    Typography,
} from '@speeki/global-ui-components';

import { PlaceholderLayout } from '@components/templates/PlaceholderLayout';

import {
    EmptySpaceWrapper,
    EnableFeatureText,
    LinkText,
    TextContent,
} from '@pages/StaticPlaceholder.styled';

export const CarbonLensPlaceholder = () => {
    const { navigate } = useNavigation();

    return (
        <PlaceholderLayout
            headingOptions={{
                icon: 'SvgEsgCalculator',
                title: 'Carbon Lens',
            }}
        >
            <TableEmptyState testId="szDeDMt" title="Welcome to Carbon Lens">
                <EmptySpaceWrapper>
                    <TextContent>
                        <Typography.TextBody_16_24>
                            Carbon Lens supports your GHG accounting across
                            Scope 1, 2, and 3 emissions.
                        </Typography.TextBody_16_24>
                        <Typography.TextBody_16_24>
                            With built-in integration to Metric, it streamlines
                            data capture and simplifies carbon reporting.
                        </Typography.TextBody_16_24>
                        <EnableFeatureText>
                            To enable this feature, please{' '}
                            <LinkText
                                href="https://www.speeki.com/company/contact-us"
                                target="_blank"
                            >
                                contact our sales team
                            </LinkText>
                            .
                        </EnableFeatureText>
                    </TextContent>

                    <Button
                        center
                        onClick={() =>
                            navigate({
                                path: '/dashboard',
                            })
                        }
                        testId="e2yumUP"
                        variant={'primary'}
                    >
                        Back to Homepage
                    </Button>
                </EmptySpaceWrapper>
            </TableEmptyState>
        </PlaceholderLayout>
    );
};
